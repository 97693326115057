import { defineComponent, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { getIllustrationsPath } from "@/core/helpers/assets";
export default defineComponent({
    name: "Under-Contruction",
    setup: function () {
        var _a, _b, _c;
        var store = useStore();
        var router = useRouter();
        var status = (_a = store.getters.getStoreData) === null || _a === void 0 ? void 0 : _a.status;
        var admin_status = (_b = store.getters.getStoreData) === null || _b === void 0 ? void 0 : _b.admin_status;
        var store_name = (_c = store.getters.getStoreData) === null || _c === void 0 ? void 0 : _c.store_name;
        // Redirect if both status and admin_status are true
        onMounted(function () {
            store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
            if (status && admin_status) {
                router.push("/");
            }
        });
        watch(function () { var _a, _b; return ({ status: (_a = store.getters.getStoreData) === null || _a === void 0 ? void 0 : _a.status, admin_status: (_b = store.getters.getStoreData) === null || _b === void 0 ? void 0 : _b.admin_status }); }, function (newVal) {
            if (newVal.status && newVal.admin_status) {
                router.push("/");
            }
        });
        onUnmounted(function () {
            store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
        });
        return {
            getIllustrationsPath: getIllustrationsPath,
            store_name: store_name,
            status: status,
            admin_status: admin_status,
        };
    },
});
